import React from 'react'
import { GatsbyBrowser } from 'gatsby'
import { App } from '@src/App'
import { Layout } from '@src/Layout'

export const wrapPageElement = Layout

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
	<App>{element}</App>
)
