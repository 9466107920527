import MontserratBlack from '@assets/fonts/Montserrat-Black.ttf'
import MontserratExtraBold from '@assets/fonts/Montserrat-ExtraBold.ttf'
import MontserratBold from '@assets/fonts/Montserrat-Bold.ttf'
import MontserratSemiBold from '@assets/fonts/Montserrat-SemiBold.ttf'
import MontserratMedium from '@assets/fonts/Montserrat-Medium.ttf'
import MontserratRegular from '@assets/fonts/Montserrat-Regular.ttf'
import MontserratLight from '@assets/fonts/Montserrat-Light.ttf'
import MontserratExtraLight from '@assets/fonts/Montserrat-ExtraLight.ttf'
import MontserratThin from '@assets/fonts/Montserrat-Thin.ttf'

const FontFace = (font: string, style: string, weight: number) => ({
  '@font-face': {
    fontFamily: 'Montserrat',
    fontWeight: weight,
    fontStyle: style,
    src: `url('${font}') format('truetype')`,
  },
})

export const globalStyles = [
  FontFace(MontserratBlack, 'normal', 900),
  FontFace(MontserratExtraBold, 'normal', 800),
  FontFace(MontserratBold, 'normal', 700),
  FontFace(MontserratSemiBold, 'normal', 600),
  FontFace(MontserratMedium, 'normal', 500),
  FontFace(MontserratRegular, 'normal', 400),
  FontFace(MontserratLight, 'normal', 300),
  FontFace(MontserratExtraLight, 'normal', 200),
  FontFace(MontserratThin, 'normal', 100),
  {
    'html, body': {
      fontFamily: 'Montserrat, sans-serif, serif',
      fontSize: '16px',
      overflow: 'hidden',
      scrollBehavior: 'smooth',
    },
    html: {
      overflowY: 'scroll',
    },
  },
]
