/* eslint-disable react/prop-types */
import { Block } from '@stage-ui/core'
import { GatsbyBrowser } from 'gatsby'

export const Layout: GatsbyBrowser['wrapPageElement'] = (props) => {
  const { element } = props
  return (
    <Block w="100vw" style={{ overflow: 'hidden' }}>
      {element}
    </Block>
  )
}
